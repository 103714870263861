<!--
 * ======================================
 * 说明： 班级  新增  修改
 * 作者： Silence
 * 文件： classroomEdit.vue
 * 日期： 2023/8/9 15:56
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <el-form ref="form" :model="form" :rules="rules" label-width="80px">
    <el-form-item label="所属年级" prop="grade">
      <el-select class="w-full" v-model="form.grade" placeholder="请选择年级">
        <el-option v-for="item in gradeOptions" :label="item.label" :value="item.value" :key="item.value"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="班级名称" prop="className">
      <el-input v-model="form.className"></el-input>
    </el-form-item>
    <el-form-item label="班级编号" prop="number">
      <el-input v-model="form.number"></el-input>
    </el-form-item>
    <el-form-item label="入学年份" prop="year">
      <el-date-picker
        style="width: 100%"
        v-model="form.year"
        type="year"
        value-format="yyyy"
        placeholder="选择年">
      </el-date-picker>
    </el-form-item>
  </el-form>
</template>
<script>
  import { grade } from '../../../utils/global.js'
  export default {
    name: '',
    components: {},
    props: {
      value:Object,
    },
    data() {
      return {
        gradeOptions: grade,
        form:{
          grade:'',
          className:'',
          number:'',
          year:'',
        },
        rules:{
          grade:[
            {required: true, message: '请选择年级', trigger: 'blur'}
          ],
          className:[
            {required: true, message: '请输入班级名称', trigger: 'blur'}
          ],
          // number:[
          //   {required: true, message: '请输入班级编码', trigger: 'blur'}
          // ],
          year:[
            {required: true, message: '请输入选择入学年份', trigger: 'blur'}
          ],
        }
      }
    },
    computed: {},
    created() {},
    mounted() {
      if(this.value.id){
        this.handleLoadForm(this.value.id)
      }else{
        this.form = {
          grade:'',
          className:'',
          number:'',
          year:'',
        }
      }
      
    },
    methods: {
      // 加载表单数据
      handleLoadForm(id){
        this.$cloud.get('/classes/detail',{id}).then(res=>{
          this.form = res
          this.form.className = res.class_name
        })
      },
      
      // 提交数据
      handleSubmit(){
        return new Promise((resolve,reject)=>{
          this.$refs['form'].validate((valid)=>{
            if(valid){
              let api = this.form.id ? '/classes/edit' : '/classes/create'
              this.$cloud.post(api,this.form).then(()=>{
                resolve(true)
              }).catch(()=>{
                reject(false)
              })
            }else{
              reject(false)
            }
          })
        })
      }
    },
  }
</script>
<style lang="scss" scoped></style>