<!--
 * ======================================
 * 说明： 班级管理
 * 作者： Silence
 * 文件： classroom.vue
 * 日期： 2023/8/1 23:52
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div class="h-full">
    <Scrollbar>
      <Table :hasAction="true" :options="handleOptions" :loadData="handleLoadData" title="班级管理" ref="table" :handleInfo="{ label: '操作', width: 380 }">
        <template #search>
          <el-form :inline="true" :model="formSearch" class="demo-form-inline">
            <el-form-item label="学期" prop="semesterId">
              <el-select v-model="formSearch.semesterId" clearable>
                <el-option v-for="item in semesterOptions" :key="item.value" :label="item.semester_name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="年级" prop="grade">
              <el-select v-model="formSearch.grade" clearable>
                <el-option v-for="item in gradeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="班级名称" prop="className">
              <el-input v-model="formSearch.className"></el-input>
            </el-form-item>
            <el-form-item label="班级编号" prop="number">
              <el-input v-model="formSearch.number"></el-input>
            </el-form-item>
            <el-form-item label="入学年份" prop="year">
              <el-date-picker
                clearable
                v-model="formSearch.year"
                type="year"
                value-format="yyyy"
                placeholder="选择年">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleRefresh">查询</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template #action>
          <div>
            <el-button type="primary" icon="el-icon-plus" @click="handleCreate">添加班级</el-button>
            <el-button type="success" icon="el-icon-download" @click="handleExport">学生导入模板</el-button>
          </div>
        </template>
        <template #table-item-semester_name="{row}">
          {{(row.semester?row.semester.semester_name : '-') || '-'}}
        </template>
        <template #handle="{index,row}">
          <el-button type="text" icon="el-icon-edit" @click="handleEdit(row)">修改</el-button>
          <el-button type="text" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
          <el-button type="text" icon="el-icon-s-custom" @click="handleTeaching(row)">任课教师</el-button>
          <el-button type="text" icon="el-icon-user" @click="handleAllStudent(row)">全班学生</el-button>
          <el-button type="text" icon="el-icon-upload2" @click="beforeUpload(row)">导入学生</el-button>
          <el-upload
            v-show="false"
            ref="upload"
            action=""
            :show-file-list="false"
            style="display: contents"
            accept=".xls,.XLS,.xlsx,.XLSX"
            :http-request="handleImport"
          >
            <el-button  icon="el-icon-upload2" type="text" class="ml-2 upload-button"
            >导入学生
            </el-button>
          </el-upload>
        </template>
      </Table>
    </Scrollbar>
    <teaching ref="Teaching" @onRefresh="handleRefresh"></teaching>
  </div>
</template>
<script>
import edit from './components/classroomEdit.vue'
import Student from './components/classroomAllStudent.vue'
import Teaching from './components/classroomTeaching.vue'
import { grade } from '@/utils/global'
import mixin from "@/mixin/execlExportImport";
export default {
  name: '',
  components: {Teaching},
  mixins: [mixin],
  props: {},
  data() {
    return {
      gradeOptions: grade,
      semesterOptions:[],
      options: {
        header: [],
        search: []
      },
      //搜索表单结构
      formSearch: {
        number:'',
        grade:'',
        className:'',
        year: '',
      },
      uploadClassId:'',
    }
  },
  computed: {},
  created() {},
  async mounted() {
    await this.handleLoadSemesterOptions();
    await this.handleRefresh();
  },
  methods: {
    async handleLoadSemesterOptions(){
      await this.$cloud.get("semester/list",{
        size:99999,
        page:1,
      }).then(res => {
          this.semesterOptions = res.children;
      })
    },

    handleOptions(table) {
      //获取分页数据结构
      this.$cloud.get("header/teaching/classes").then(res => {
        let {header, search} = res;
        table.setHeader(header);
        table.setSearch(search);
      }).catch(err => {
        table.errMsg(err)
      })
    },
    handleLoadData(table, {size, page}) {
      //获取分页数据结构
      this.$cloud.get("/classes/list", {size, page,...this.formSearch}).then(res => {
        table.setData(res);
      }).catch(err => {
        table.errMsg(err)
      })
    },
    // 添加班级
    handleCreate() {
      this.$cloud.dialog({
        title: '添加班级',
        data: {},
        component: edit,
        success: '添加成功！',
        warning: '添加失败！',
        refresh: this.handleRefresh
      })
    },

    // 更新班级
    handleEdit(item) {
      this.$cloud.dialog({
        title: '更新班级',
        data: item,
        component: edit,
        success: '更新成功！',
        warning: '更新失败！',
        refresh: this.handleRefresh
      })
    },

    handleDelete(item) {
      this.$confirm('此操作将永久删除该班级吗？ 是否继续!', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$cloud.post("/classes/remove", {id: item.id}).then(() => {
          this.$message.success('删除成功！')
          this.handleRefresh()
        })
      })
    },

    // 任课教师
    handleTeaching(item){
      this.$refs['Teaching'].open(item)
    },

    // 全班学生
    handleAllStudent(item){
      this.$cloud.dialog({
        title: '全班学生',
        data: item,
        component: Student,
        success: '确定',
        warning: '取消',
        customClass: "all-student-classroom",
      })
    },

    // 学生导入模板
    handleExport() {
      let tHeader = ['姓名',"学号","级数","班级","性别","家长姓名","家长手机（钉钉）"];
      this.exportElxs({
        header: tHeader, //这里应该是算第二行的表头
        data: [], //数据
        filename: `学生信息采集模板`,
        autoWidth: true,
        bookType: "xlsx",
      });
    },

    // 上传之前提示
    beforeUpload(data){
        this.$confirm('导入学生将会先清除当前班级的学生,再导入新的学生, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.uploadClassId = data.id
          console.log(this.uploadClassId)
          this.$nextTick(() => {
            this.$refs.upload.$el.querySelector('.upload-button').click();
          })
        })
    },

    // 导入学生
    handleImport(e){
      this.httpRequest(e,(data)=>{
        if(data && data.length){
          let params = {
            classId:this.uploadClassId,
            list:data.map(item=>{
              return {
                studentName:item['姓名'],
                studentNumber:item['学号'],
                classYear:item['级数'],
                className:item['班级'],
                sex:item['性别'],
                parentName:item['家长姓名'],
                parentMobile:item['家长手机（钉钉）'],
              }
            })
          }
          this.$cloud.post('student/import2',params).then(res=>{
            this.$message.success('导入成功!')
            this.handleRefresh()
          })
        }
      })
    },

    // 刷新
    handleRefresh() {
      this.$nextTick(() => {
        this.$refs['table'].resetPage()
        this.$refs['table'].handleLoadData()
      })
    }
  },
}
</script>
<style lang="scss">
.all-student-classroom{
  width: 800px;
}
</style>
