<!--
 * ======================================
 * 说明： 班级下的任课列表
 * 作者： Silence
 * 文件： classroomTeaching.vue
 * 日期： 2023/9/3 0:35
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <Dialog width="700px" height="600px" title="任课教师" type="view" :visible.sync="visible">
      <el-form v-loading="loading" class="scroll" label-width="200px">
        <el-form-item v-for="item in subjectList" :key="'form-' + item.id" :label="item.name">
          <el-select v-model="item.teacher_id" filterable @change="handleEdit($event,item)" placeholder="请选择老师">
            <el-option
                v-for="item in teacherList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              <div class="flex">
                <div>{{item.label}}</div>
                <div class="ml-auto">{{item.mobile}}</div>
              </div>
            </el-option>
            <el-option label="无" value=""></el-option>
          </el-select>
        </el-form-item>
      </el-form>
  </Dialog>
</template>
<script>
  export default {
    name: '',
    components: {  },
    props: {},
    data() {
      return {
        value: null,
        visible:false,
        loading:false,
        subjectList:[],
        teacherList:[]
      }
    },
    mounted() {
      this.handleLoadTeachers()
    },
    methods: {
      // 获取所有教师
      handleLoadTeachers(){
        this.$cloud.get('/teacher/list',{
          page:1,
          size:99999,
        }).then(res=>{
          this.teacherList = res.children.map(v=>{
            v['label'] = v['real_name']
            v['value'] = v['id']
            return v
          })
        })
      },
      open(data){
        this.value = data;
        this.visible = true
        this.handleLoadTable();   // 获取任课老师
      },
      // 加载表格数据
      handleLoadTable(){
        this.loading = true
        this.$cloud.get('classes/teaching/subject/list',{classId:this.value.id}).then(res => {
          this.loading = false
          if(res){
            this.subjectList = res.map(v=>{
              v['label'] = v['name']
              v['value'] = v['id']
              return v
            })
          }else{
            this.subjectList = []
          }
        }).catch(err=>{
          this.loading = false
          // this.$message.error(err?.response?.data?.msg || '加载失败')
        })
      },
      // 修改任课
      handleEdit(e,row){
        console.log("老师",e)
        console.log("学科",row)
        let data = {
          "classId":this.value.id,
          "subjectId":row.id,
          "teacherId":e
        }
        console.log("修改",data)
        this.$cloud.post('classes/teaching/subject/edit',data).then(()=>{
          this.$message.success("修改成功")
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
.scroll{
  height: 100%;
  overflow-y: auto;
}
</style>
