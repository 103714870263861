<!--
 * ======================================
 * 说明： 全班学生
 * 作者： Silence
 * 文件： classroomAllStudent.vue
 * 日期： 2023/9/2 23:38
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <el-table :data="tableData" :height="500">
    <el-table-column type="index">
      <template #default="scope">
        {{ scope.$index + 1 }}
      </template>
    </el-table-column>
    <el-table-column prop="number" label="学号"></el-table-column>
    <el-table-column prop="real_name" label="姓名"></el-table-column>
    <el-table-column prop="sex" label="性别"></el-table-column>
    <el-table-column prop="status" label="状态"></el-table-column>
  </el-table>
</template>
<script>
  export default {
    name: '',
    components: {},
    props: {
      value:{
        type: Object,
      }
    },
    data() {
      return {
        tableData:[]
      }
    },
    computed: {},
    created() {},
    mounted() {
      this.handleLoadStudent(this.value.id)
    },
    methods: {
      // 加载全部学生
      handleLoadStudent(){
          this.$cloud.get('student/list',{
            page:1,
            size:99999,
            classId:this.value.id
          }).then(res=>{
            if(res?.children?.length){
              this.tableData = res.children
            }else{
              this.tableData = []
            }
          })
      }
    },
  }
</script>
<style lang="scss" scoped></style>