import { render, staticRenderFns } from "./classroomTeaching.vue?vue&type=template&id=329b8a7f&scoped=true"
import script from "./classroomTeaching.vue?vue&type=script&lang=js"
export * from "./classroomTeaching.vue?vue&type=script&lang=js"
import style0 from "./classroomTeaching.vue?vue&type=style&index=0&id=329b8a7f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "329b8a7f",
  null
  
)

export default component.exports